import { createSlice } from "@reduxjs/toolkit";
import LocationsRepository from "../../../../repositories/locations";
import { toast } from "react-toastify";
const toastConfig = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialState = {items: []};
export const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    load: (state, action) => {
      state.items = [...action.payload];
    },
    add: (state, { payload }) => {
      state.items = [...state.items, payload.payload];
    },
    update: (state, { payload }) => {
      state.items = state.items.map((location) => {
        if (location.id === payload.id) {
          return {...location,  ...payload.data};
        }
        return location;
      });
    },
    delete: (state,  {payload}) => {
      state.items = state.items.filter((loc) => loc.id !== payload);
    }
  },
});

export const getLocationsByStore = () => async (dispatch) => {
  const repository = new LocationsRepository();
  const response = await repository.getLocationsByStore();
  if (response) {
    dispatch(locationSliceActions.load(response))
  } else {
    toast.warn(
      "It seems something has gone wrong, please try again",
      toastConfig
    );
  }
};




export const locationSliceActions = locationSlice.actions;
export default locationSlice.reducer;
