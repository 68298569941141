import React, { useState, useEffect } from "react";
import { ProfileSidePanel } from "../Helpers/ProfileSidePanel";
import PropTypes from "prop-types";
import { Row, Col, Button, Container } from "reactstrap";
import "../../assets/scss/custom/pages/_prizesetup-info.scss";
import SidePanelImg from "../../assets/images/layouts/prize-info-img2.png";
import { StoreRepository } from "../../repositories/store";
import { GENERIC_CATEGORY } from "../../core/constants/store";

const PrizeSetUpInstruction = (props) => {
  const { history } = props;
  const [imageCategory, setImageCategory] = useState(null);
  const [description, setDescription] = useState("");
  const [header, setHeader] = useState("");
  const [category, setCategory] = useState(null);

  const handleClick = () => {
    history.push("/prize-setup/1");
  };

  useEffect(() => {
    new StoreRepository()
      .getStore()
      .then((data) => {
        const cat = data.store_category_id;
        setCategory(cat);
        setImageCategory(cat.prize_image_url);
        setDescription(cat.description);
        setHeader(cat.header);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="prize-info p-0 m-0">
      <Container fluid className="m-0 p-0">
        <div className="prize-info-wrp">
          <ProfileSidePanel
            isActive={false}
            image={SidePanelImg}
            imgClass={"prize-info-img"}
            isGeneric={category?.name === GENERIC_CATEGORY}
          />
          <div className="right-side-content">
            <Row>
              <Col className="m-0 p-0 content-heading">
                <div dangerouslySetInnerHTML={{ __html: header }}></div>
              </Col>
            </Row>
            <div className="prize-info-section">
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></div>
            </div>
            <div className="prize-info-img mt-5 mx-lg-2">
              <img
                className="img-fluid"
                src={imageCategory}
                width="980"
                height="590"
                alt="prizeinfoimg"
              ></img>
            </div>
            <div className="line_button_section mt-5">
              <Row className="align-items-center">
                <Col className="m-0 p-0" md={0} lg={6} xl={0}>
                  <div className="line_section"></div>
                </Col>
                <Col className="m-0 py-2" md={0} lg={4} xl={0}>
                  <div className="line_button">
                    <Button onClick={handleClick} className="next-btn">
                      CONFIGURE AWARDS{" "}
                      <i className="fa-solid fa-angle-right text-white"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

PrizeSetUpInstruction.propTypes = {
  history: PropTypes.object,
};

export default PrizeSetUpInstruction;
