import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { AddPaymentMethod } from "./AddPaymentMethod";
import { PaymentMethodRepository } from "../../repositories/paymenMethod";
import { StoreSubscriptionsRepository } from "../../repositories/store_subscriptions";
import { CANECEL_ADD_EVENT, LOAD_EVENT } from "./AddPaymentMethod/constants";
import paymentForm from "../../assets/images/layouts/payment-img.svg";
import beeLogo from "../../assets/images/logo-light.svg";
import "../../assets/scss/custom/pages/_paymentform.scss";
import { useTranslation } from "react-i18next";

const PaymentForm = (props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  document.title = "Payment";
  const { history } = props;

  const { t } = useTranslation();

  useEffect(() => {
    const validateSubscription = () => {
      new StoreSubscriptionsRepository()
        .getSubsciption()
        .then((data) => {
          if (data.subscription_active) {
            history.push("/profile-details");
          }
        })
        .catch((error) => console.log(error));
    };

    validateSubscription();

    const handlerPayment = async (e) => {
      setIsProcessing(true);
      const repository = new PaymentMethodRepository();
      const response = await repository.register(e.detail);
      setIsProcessing(false);
      if (response.status === 201) {
        history.push("/how-it-works");
      } else {
        Swal.fire({
          title: "Error!",
          text:
            response.response?.data?.description ||
            "It seems something has gone wrong, please try again",
          icon: "error",
          confirmButton: true,
        }).then((result) => window.location.reload());
      }
    };

    window.addEventListener(LOAD_EVENT, handlerPayment);

    const handleCancel = () => {};

    window.addEventListener(CANECEL_ADD_EVENT, handleCancel);
  }, [history, t]);

  const refreshMethods = (payload) => {
    window.dispatchEvent(
      new CustomEvent("refresh-methods", { detail: payload })
    );
  };

  const cancelAddMethod = () => {
    window.dispatchEvent(new CustomEvent("cancel-add-method"));
  };

  return (
    <React.Fragment>
      <div
        className="payment-form register_form"
        style={{ height: window.innerHeight }}
      >
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <div className="account-pages mt-0 pt-0">
          <Container className="p-0 m-0 main_div">
            <Row className="justify-content-center">
              <Col className="p-0" md={0} lg={0} xl={8}>
                <div className="form_side_img p-4 text-center">
                  <img src={paymentForm} alt="" className="img-fluid" />
                </div>
              </Col>
              <Col className="p-0" md={0} lg={0} xl={4}>
                <Card className="overflow-hidden">
                  <div>
                    <Row className="bee_img">
                      <Col className="col-12">
                        <Col className="col-12 mt-4">
                          <img src={beeLogo} alt="" width="130" />
                        </Col>
                        <div className="form_upper_text mt-4">
                          <h1>Payment</h1>
                          <p className="m-0 muted">
                            Last Step! You Are Almost Done!
                          </p>
                          <AddPaymentMethod
                            onMethodAdded={refreshMethods}
                            onCancel={cancelAddMethod}
                            isProcessing={isProcessing}
                          />
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end"></Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <div
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      ></div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentForm;
