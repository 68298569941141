import { axiosApi } from "../helpers/api_helper";

export class PrizeRepository {
  endpoint = "/prizes";

  async listAll() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async listByStoreCategoryIdAndCountryID() {
    const response = await axiosApi.get(`${this.endpoint}`);

    return response.data;
  }

  async getCurrentStatistic(params) {
    const response = await axiosApi.get(
      `${this.endpoint}/statistics-by-current-month`, {params}
    );

    return response.data;
  }

  async getRedeemedStatistic(filter, start_date, end_date) {
    const response = await axiosApi.get(
      `${this.endpoint}/count-prizes-redeemed`,
      { params: { filter_type: filter, start_date, end_date } }
    );

    return response.data;
  }

  async getCurrentPrizes() {
    const response = await axiosApi.get(`${this.endpoint}/current-prizes`);
    return response.data;
  }
  async getRedeemedStatisticActivity(start_date, end_date) {
    const response = await axiosApi.get(`${this.endpoint}/actions`, {
      params: { start_date, end_date },
    });

    return response.data;
  }

  async getRedeemedStatisticAge(start_date, end_date) {
    const response = await axiosApi.get(
      `${this.endpoint}/consume-by-years-old`,
      { params: { start_date, end_date } }
    );

    return response.data;
  }

  async getRedeemedStatisticMembers(start_date, end_date, filter_type) {
    const response = await axiosApi.get(
      `${this.endpoint}/membership-count-by-date`,
      { params: { start_date, end_date, filter_type } }
    );

    return response.data;
  }

  async getRedeemedStatisticMembersGender(start_date, end_date) {
    const response = await axiosApi.get(`${this.endpoint}/consume-by-gender`, {
      params: { start_date, end_date },
    });

    return response.data;
  }
}

export default PrizeRepository;
