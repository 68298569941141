import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  FormFeedback,
  Label,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoginRepository } from "../../repositories/login";
import logo from "../../assets/images/logo-bee-circle.svg";
import wp from "../../assets/images/grupo-995.svg";
import "../../assets/scss/custom/pages/_login.scss";

const Login = (props) => {
  const { history } = props;
  const [error, setError] = useState("");
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(
        "Please give an E-mail adress"
      ),
      password: Yup.string().required("Please enter your password"),
    }),
    onSubmit: (values) => {
      const repo = new LoginRepository();
      repo
        .login(values)
        .then((data) => {
          if (data?.user_data?.store?.configuration_completed) {
            history.push("/dashboard");
          } else {
            history.push("/payment");
          }
        })
        .catch((err) => {
          setError(err.message);
        });
    },
  });

  useEffect(() => {
    const info = new LoginRepository().getInfoUser();
    if (info) {
      if (info.store?.configuration_completed) {
        history.push("/dashboard");
      } else {
        history.push("/payment");
      }
    }
  }, [history]);

  return (
    <Container>
      <Row className="pt-5">
        <Col lg={{ offset: 3, size: 6 }}>
          <Card className="shadow">
            <div className="bg-well text-black pb-4">
              <Row>
                <Col md="7">
                  <div className=" p-4">
                    <h3
                      style={{
                        font: 'normal normal 900 40px/21px "Avenir"',
                        color: "#454847",
                      }}
                    >
                      Welcome!
                    </h3>
                    <p
                      style={{
                        font: 'normal normal medium 20px/24px "Avenir"',
                        letterSpacing: "-0.48px",
                        color: "#454847",
                      }}
                    >
                      Log in to your account with
                      <br />
                      <strong
                        style={{
                          font: 'normal normal 900 20px/24px "Avenir"',
                          letterSpacing: " -0.48px",
                          color: "#454847",
                        }}
                      >
                        BeeCard Business
                      </strong>
                    </p>
                  </div>
                </Col>
                <Col md="5" className="align-self-end ps-1">
                  <img src={wp} alt="" className="img-fluid" />
                </Col>
              </Row>
            </div>

            <CardBody className="pt-0">
              <div className="auth-logo-light">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title rounded-circle bg-light">
                    <img
                      src={logo}
                      alt=""
                      className="rounded-circle"
                      height="90"
                    />
                  </span>
                </div>
              </div>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}

                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="username"
                    placeholder="Enter your email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">Password</Label>
                  <Input
                    name="password"
                    value={validation.values.password || ""}
                    type="password"
                    placeholder="Enter your password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customControlInline"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customControlInline"
                  >
                    Remember ne
                  </label>
                </div>
                <div className="mt-3 d-grid ">
                  <button
                    className="btn btn-block  bg-primary text-white"
                    type="submit"
                  >
                    Log in
                  </button>
                </div>

                <div className="mt-4 text-center">
                  <Link to="/forgot-password" className="text-muted">
                    <i className="mdi mdi-lock me-1" />
                      Forgot your password?
                  </Link>
                </div>
                <div className="mt-5 text-center">
                  <p>
                    Don't have an account?
                    <Link to="/register" className="fw-medium text-primary">
                      {" "}
                      Register here{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
