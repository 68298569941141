import React, { useEffect, useRef, useState } from "react";
import { LoadingSpinner } from "../../../components/Common/LoadingSpinner/LoadingSpinner";
import "./index.css";

export const AddPaymentMethod = (props) => {
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  const { onMethodAdded, onCancel, isProcessing } = props;

  const handleAddPaymentMethod = async (data) => {
    const { TokenDetails } = data;
    const payload = {
      account_number: TokenDetails.AccountNumber,
      card_number: TokenDetails.CardNumber,
      card_name: TokenDetails.CardHolderName,
      token: TokenDetails.AccountToken,
    };

    onMethodAdded(payload);
  };

  const eventStorageHandler = (e) => {
    const data = e.detail;
    if (data) {
      handleAddPaymentMethod(data);
    }
  };

  const configureCvv = () => {
    const borderLine = document.querySelector(".border-line");
    if (borderLine) {
      const node = `<div class="card-ui-component-row-static"><div 
      class="card-ui-component-label-style"><label for="Cardholder" 
      >CVV</label></div><div class="card-ui-component-input-style"><input 
      class="card-ui-component-field" data-val="true" data-val-regex="Cardholder is invalid"
      data-val-regex-pattern="^[0-9]{3,4}$" dataval-required="Cvv no es válido" id="card-ui-component-txt-cvv" 
      maxlength="4" name="Cvv" type="password" value=""></div></div>`;
      borderLine.innerHTML += node;
    }

    const form = document.querySelector("#card-ui-component-form-creditcard");
    form?.querySelectorAll("input").forEach((input) => {
      input.classList.remove("card-ui-component-field");
      input.classList.add("form-control");
      input.setAttribute("required", "true");
    });

    document
      .querySelectorAll("input.card-ui-component-field")
      .forEach((node) => node.classList.add("form-control"));

    const cancelBtn = document.querySelector("#card-ui-component-btn-cancel");
    const continueBtn = document.querySelector(
      "#card-ui-component-btn-confirm"
    );

    if (continueBtn) {
      continueBtn.innerHTML = "CONTINUE";
    }

    if (cancelBtn) {
      cancelBtn.addEventListener("click", onCancel);
    }
  };

  const fetchForm = async () => {
    const params = new URLSearchParams({
      APIKey: process.env.REACT_APP_MERCHANT_API_KEY,
      Token: process.env.REACT_APP_MERCHANT_TOKEN,
      Culture: process.env.REACT_APP_MERCHANT_CULTURE,
    });
    setLoading(true);
    const url = process.env.REACT_APP_MERCHANT_FORM_URL;
    const response = await fetch(`${url}?${params}`);
    if (response.ok) {
      const script = document.createElement("script");
      script.innerHTML = `
            var onMethodAdded = ${onMethodAdded};
            var SaveCreditCard_SuccessCallback = (e) => {
                window.dispatchEvent(new CustomEvent("event-set-payment", {'detail': e}));
            }`;
      ref.current.appendChild(script);
      const content = await response.text();
      ref.current.appendChild(
        document.createRange().createContextualFragment(`<div>
          ${content}
        </div>`)
      );
      configureCvv();
    }
    setLoading(false);
  };

  useEffect(() => {
    const load = async () => {
      await fetchForm();
    };

    load();

    window.addEventListener("event-set-payment", eventStorageHandler);

    return () => {
      window.removeEventListener("event-set-payment", eventStorageHandler);
    };
  }, []);

  return (
    <>
      <div id="form-template" ref={ref}>
        {loading ? <LoadingSpinner /> : null}
      </div>
      {isProcessing ? <LoadingSpinner /> : null}
    </>
  );
};
