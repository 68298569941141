import { createSlice } from "@reduxjs/toolkit";
import { FILTER_ALL, FILTER_CURRENT_MONTH } from "../../../../../core/constants/prize_redeemed_filter";

const initialState = {
  isLoading: false,
  error: null,
  data: [],
  filter: FILTER_CURRENT_MONTH,

  dataActivity: {
    data: {
      label: [],
      data: [],
    },
    filter: FILTER_ALL,
  },
  dataAge: {
    data: {
      label: [],
      data: [],
    },
  },
  dataMembers: {
    data: {
      label: [],
      data: [],
    },
    filter: FILTER_CURRENT_MONTH,
  },
  dataMembersGender: {
    data: {
      label: [],
      data: [],
    },
  },
};

export const prizeRedeemedSlice = createSlice({
  name: "prize/redeemed",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    loaded: (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.data = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setActivity: (state, action) => {
      state.dataActivity.data = action.payload;
    },
    setFilterActivity: (state, action) => {
      state.dataActivity.filter = action.payload;
    },
    setAge: (state, action) => {
      state.dataAge.data = action.payload;
    },
    setMembers: (state, action) => {
      state.dataMembers.data = action.payload;
    },
    setFilterMembers: (state, action) => {
      state.dataMembers.filter = action.payload;
    },
    setMembersGender: (state, action) => {
      state.dataMembersGender.data = action.payload;
    },
  },
});
export const prizeRedeemedActions = prizeRedeemedSlice.actions;
export default prizeRedeemedSlice.reducer;
