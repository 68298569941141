import axios from "axios";
import { toast } from "react-toastify";
import { LoginRepository } from "../repositories/login";

const API_URL = process.env.REACT_APP_BASE_URL;
const COMMERCE_API_URL = process.env.REACT_APP_COMMERCE_URL;

export const axiosApi = axios.create({
  baseURL: API_URL,
});

export const commerceApi = axios.create({
  baseURL: COMMERCE_API_URL,
});

const addTokenInterceptor = (config) => {
  const loginRepository = new LoginRepository();
  const token = loginRepository.getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const errorLogInterceptor = async (error) => {
  const statusCode = error.response.status,
    url = error.response.config.url;
  if (statusCode === 401 && !["/auth/login", "/auth/register"].includes(url)) {
    const repository = new LoginRepository();
    repository.logOut();
    window.location.href = "/login";
  } else if (statusCode >= 500) {
    toast.error("Something went wrong", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return error;
};

axiosApi.interceptors.request.use(addTokenInterceptor);
axiosApi.interceptors.response.use(undefined, errorLogInterceptor);

commerceApi.interceptors.request.use(addTokenInterceptor);
commerceApi.interceptors.response.use(undefined, errorLogInterceptor);
