import React from "react";
import { Row, Col, Container } from "reactstrap";
import "../../assets/scss/custom/pages/_profile-side-panel.scss";
import beeLogo from "../../assets/images/logo-light.svg";

export const ProfileSidePanel = (props) => {
  const { image, imgClass, isActive, isGeneric, imgWrapperClass } = props;
  return (
    <div className="side-panel" style={{ height: window.innerHeight }}>
      <Container fluid>
        <Row>
          <Col className="m-0 p-0" md={0} lg={12} xl={12}>
            <div className="brand_logo">
              <img
                src={beeLogo}
                alt=""
                className="img-fluid"
                width="240"
                height="80"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-0 p-0" md={0} lg={12} xl={12}>
            <div className="sidepanel-details pt-2">
              <div className="side-links mt-5">
                <p
                  className="m-0 p-0 number"
                  style={{ color: isActive === 1 ? "#DEA01A" : "#D2D3D4" }}
                >
                  1
                </p>
                <div className="side_penel_links">
                  <p className="m-0 p-0 heading_text">Choose Your Logo</p>
                  <p className="m-0 p-0 subtitle">
                    Set your profile photo
                  </p>
                </div>
              </div>
              {isGeneric ? (
                <>
                  {/*<div className="side-links mt-5 mb-3">
                    <p
                      className="m-0 p-0 number"
                      style={{ color: isActive === 2 ? "#DEA01A" : "#D2D3D4" }}
                    >
                      2
                    </p>
                    <div className="side_penel_links">
                      <p className="m-0 p-0 heading_text">Define los Sellos</p>
                      <p className="m-0 p-0 subtitle">
                        Digita el monto de los sellos de tarjeta
                      </p>
                    </div>
                  </div>*/}
                  <div className="side-links mt-5 mb-3">
                    <p
                      className="m-0 p-0 number"
                      style={{ color: isActive === 3 ? "#DEA01A" : "#D2D3D4" }}
                    >
                      2
                    </p>
                    <div className="side_penel_links">
                      <p className="m-0 p-0 heading_text">
                        Select Your Awards
                      </p>
                      <p className="m-0 p-0 subtitle">
                        Choose the awards you want to give
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="side-links mt-5 mb-3">
                  <p
                    className="m-0 p-0 number"
                    style={{ color: isActive === 2 ? "#DEA01A" : "#D2D3D4" }}
                  >
                    2
                  </p>
                  <div className="side_penel_links">
                    <p className="m-0 p-0 heading_text">
                      Select Your Awards
                    </p>
                    <p className="m-0 p-0 subtitle">
                      Choose the awards you want to
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={imgWrapperClass} md={0} lg={12} xl={12}>
            <div className={`${imgClass}`}>
              <img src={image} alt="img" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ProfileSidePanel.defaultProps = {
  isGeneric: false,
  imgWrapperClass: "mt-5 p-0 pt-4",
};
