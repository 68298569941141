import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import "../../assets/scss/custom/pages/_welcome-two.scss";
import logo from "../../assets/images/logo-white.svg";
import phoneImg from "../../assets/images/layouts/wlcm-two.png";
import { useHistory } from "react-router-dom";
const WelcomeOne = () => {
  const history = useHistory();
  return (
    <div
      className="wlcm-page-two m-0 p-0"
      style={{ height: window.innerHeight }}
    >
      <Container fluid>
        <Row>
          <Col xl={5} lg={5} md={5} sm={12} xs={12}>
            <div className="wlcm-two-wrp mt-5">
              <img className="bee-logo" alt="logo" src={logo} />
              <div className="title mt-5 pt-3">
                <p>It's That Easy!</p>
              </div>
              <div className="sub-title">
                <p>You are all set! You can start using the app now.</p>
              </div>
              <div className="sub-title mt-5">
                <p>Tell your customers so they can start earning rewards!</p>
              </div>
              <div className="btn-div mt-5">
                <Button
                  onClick={() => history.push("/dashboard")}
                  className="continue-btn mt-4"
                >
                  CONTINUE <i className="fa-solid fa-angle-right"></i>
                </Button>
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="side-img">
              <img
                className="phone-img"
                alt="logo"
                src={phoneImg}
                height={window.innerHeight}
                width={window.innerHeight}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WelcomeOne;
