import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import logo from "../../assets/images/logo-white.svg";
import "../../assets/scss/custom/pages/_finish-up.scss";

const FinishUpPage = (props) => {
  const { history } = props;
  return (
    <div className="finish-up-page" style={{ height: window.innerHeight }}>
      <Container fluid={true}>
        <Row>
          <Col md={12}>
            <div className="logo-div">
              <img className="bee-logo" alt="logo" src={logo} />
            </div>
            <div className="main-heading">
              <p>Hello, Again!</p>
            </div>
            <div className="main-head-details">
              Finish setting up your account in <br />
              2 simple steps!
            </div>
            <div className="continuar-button mt-5">
              <Button
                onClick={() => history.push("/profile-details")}
                className="continue-btn"
              >
                CONTINUE <i className="fa-solid fa-angle-right"></i>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FinishUpPage;
