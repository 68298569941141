import { axiosApi } from "../helpers/api_helper";

export class PaymentMethodRepository {
  endpoint = "/store_payment_methods";

  async list() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async register(data) {
    const response = await axiosApi.post(
      `${this.endpoint}/first-payment`,
      data
    );
    return response;
  }

  async update(data) {
    const response = await axiosApi.post(this.endpoint, data);

    return response;
  }

  async changePaymentMethod(data) {
    return await axiosApi.post(this.endpoint, data);
  }
}

export default PaymentMethodRepository;
