import { axiosApi } from "../helpers/api_helper";

export class StoreSubscriptionsRepository {
  endpoint = "/store_subscriptions";

  async getSubsciption() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async getRedeemedPrizes(params = {}) {
    const response = await axiosApi.get(
      `${this.endpoint}/redeemed-prizes-list`,
      { params }
    );
    return response.data;
  }

  async getMembers(params = {}) {
    const response = await axiosApi.get(`${this.endpoint}/member-list`, {
      params,
    });
    return response.data;
  }

  async getMemberInfo(id) {
    const response = await axiosApi.get(
      `${this.endpoint}/member-all-info/${id}`
    );
    return response.data;
  }
}

export default StoreSubscriptionsRepository;
