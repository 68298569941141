import React, { useState, useEffect } from "react";
import { Container, Button, Input, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { ProfileSidePanel } from "../Helpers/ProfileSidePanel";
import SidePanelImg from "../../assets/images/layouts/prize-info-img2.png";
import alertCircle from "../../assets/images/logo/alert-circle-outline.png";
import leftArrow from "../../assets/images/logo/left-arrow.png";
import "../../assets/scss/custom/pages/_prizesetup-one.scss";
import { PrizeRepository } from "../../repositories/prize";
import { PrizeOptionsRepository } from "../../repositories/prizeOptions";
import { numberWithCommas } from "../../helpers/funtions";

let MAX_SETUP = 3;
let CONFIG_STEP = [];
let prize;
const titleMap = {
  1: "Premio #1",
  2: "Premio #2",
  3: "Premio #3",
};

const GenericSealSetUp = (props) => {
  const { history } = props,
    params = useParams(),
    pageNumber = Number(params.pageNumber) || 1,
    [newOption, setNewOption] = useState(""),
    [configStep, setConfigStep] = useState({}),
    [options, setOptions] = useState([]),
    [checkedOptions, setCheckedOptions] = useState([]),
    [checkedInitialOptions, setCheckedInitialOptions] = useState([]);

  useEffect(() => {
    setCheckedOptions([]);
    setCheckedInitialOptions([]);

    const repository = new PrizeRepository();
    const optionsRepository = new PrizeOptionsRepository();
    repository
      .listByStoreCategoryIdAndCountryID()
      .then((data) => {
        MAX_SETUP = data.length;

        CONFIG_STEP = data.map((item) => ({
          id: item.id,
          amount: item.country_id.currency + numberWithCommas(item.amount),
          name: item.name,
          prize_number: Number(item.prize_number),
          example: item.example,
        }));

        if (CONFIG_STEP.length > pageNumber - 1) {
          let _prize = CONFIG_STEP.filter(
            (item) => item.prize_number === pageNumber
          );

          _prize = _prize.length ? _prize[0] : CONFIG_STEP[pageNumber - 1];
          prize = _prize;
          prize.id = Number(prize.id);
          setConfigStep(_prize);
          optionsRepository
            .listPrizeIdOrStoreId(_prize.id)
            .then((options) => {
              let checked = options
                .filter((item) => item.checked)
                .map((item) => item.id);

              setCheckedOptions([...checked]);
              setCheckedInitialOptions([...checked]);
              setOptions(
                options.map((option) => ({
                  id: option.id,
                  key: option.name,
                  checked: option.checked || false,
                }))
              );
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => console.log(error));
  }, [pageNumber]);

  const handleCheckBoxAdd = () => {
    if (newOption.length) {
      const optionsRepository = new PrizeOptionsRepository();
      optionsRepository
        .registerCustomOption({
          name: newOption,
          prize_id: prize.id,
        })
        .then((data) => {
          let newOpt = {
            id: data.id,
            key: newOption,
            checked: true,
          };
          setOptions([...options, newOpt]);
          setNewOption("");
          setCheckedOptions([...checkedOptions, newOpt.id]);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleChecked = (checked, item) => {
    if (checked) {
      setCheckedOptions([...checkedOptions, item.id]);
    } else {
      setCheckedOptions(checkedOptions.filter((it) => it !== item.id));
    }
    setOptions(
      options.map((i) => {
        return i.id === item.id ? { ...item, checked: !i.checked } : i;
      })
    );
  };

  const handleBackRoute = () => {
    pageNumber === 1
      ? history.push("/setup-seals")
      : history.push(`/setup-generic-prize/${pageNumber - 1}`);
  };

  const handleNextRoute = () => {
    if (
      checkedOptions.length !== checkedInitialOptions.length ||
      checkedOptions.filter((item) => !checkedInitialOptions.includes(item))
        .length > 0
    ) {
      const optionsRepository = new PrizeOptionsRepository();
      optionsRepository
        .registerSelectedOptions({
          prize_id: prize.id,
          options: checkedOptions,
        })
        .then((_) => {
          pageNumber === MAX_SETUP
            ? history.push("/wlcm-two")
            : history.push(`/setup-generic-prize/${pageNumber + 1}`);
        })
        .catch((error) => console.log(error));
    } else {
      pageNumber === MAX_SETUP
        ? history.push("/wlcm-two")
        : history.push(`/setup-generic-prize/${pageNumber + 1}`);
    }
  };

  const getTitleStep = (step) => titleMap[step];

  return (
    <div className={`prize-setup-one m-0 p-0`}>
      <Container fluid className="m-0 p-0">
        <div className="prize-wrp-page">
          <ProfileSidePanel
            isActive={3}
            image={SidePanelImg}
            imgClass="generic-prize-info-img"
            isGeneric
          />
          <div className="left-side-content">
            <div className="price-wrp-setup-one">
              <div className="award-section mt-4">
                <span className="award-title">{getTitleStep(pageNumber)}</span>
                <p className="award-text">
                  Select the prizes that you would be willing to give to a
                  customer that has consumed {configStep.amount} in your business:
                </p>
              </div>
              <div className="checkbox-section mt-5">
                <Row className="d-flex checkbox-group-wrp">
                  {options.map((option) => {
                    return (
                      <Col
                        key={option.id}
                        className="m-0 p-0"
                        md={0}
                        lg={4}
                        xl={0}
                      >
                        <div className="checkbox-listing py-2">
                          <Input
                            type="checkbox"
                            id={option.id}
                            name={option.id}
                            checked={option.checked}
                            disabled={
                              !checkedOptions.includes(option.id) &&
                              checkedOptions.length > 2
                            }
                            onChange={(event) =>
                              handleChecked(event.target.checked, option)
                            }
                          />{" "}
                          <label htmlFor={option.key}>{option.key}</label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <div className="add-award-content">
                <p className="add-award-text">Add your options</p>
                <div className="input_section">
                  <Input
                    type="text"
                    name="addOption"
                    placeholder="Prize Name"
                    className="form-control"
                    value={newOption}
                    onChange={(event) => setNewOption(event.target.value)}
                    disabled={!(checkedOptions.length < 3)}
                  />
                  <Button
                    disabled={!(checkedOptions.length < 3)}
                    onClick={handleCheckBoxAdd}
                    className="btn-add"
                  >
                    ADD +
                  </Button>
                </div>
                <div className="award-content-span generic-examples mt-3">
                  {" "}
                  <i>Examples:</i>
                </div>
                <ul className="ul-nod generic-examples">
                  <li className="golden-li">A cup of coffee</li>
                  <li className="golden-li">
                    A product you want people to try
                  </li>
                  <li className="golden-li">
                  25% discount on your next purchase
                  </li>
                </ul>
              </div>
              <div className="option-section mt-lg-5 pt-lg-5">
                <p className="info-text pt-5">
                  <img src={alertCircle} alt="alert-circle" /> You must select
                  minimum 1 option and maximum 3 options
                </p>
              </div>
              <div className="footer-buttons">
                <Button onClick={handleBackRoute} className="back_button">
                  {" "}
                  <img src={leftArrow} alt="left-arrow" /> GO BACK
                </Button>
                <Button
                  onClick={handleNextRoute}
                  disabled={!(checkedOptions.length >= 1)}
                  className={`${
                    checkedOptions.length >= 1 && "btn-active"
                  } foward_button`}
                >
                  CONTINUE <i className="fa-solid fa-angle-right"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GenericSealSetUp;
