import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import "../../assets/scss/custom/pages/_welcome-one.scss";
import logo from "../../assets/images/logo-white.svg";
import phoneImg from "../../assets/images/layouts/wlcm-one.png";
const WelcomeOne = (props) => {
  const { history } = props;
  return (
    <div className="wlcm-page-one m-0 p-0">
      <Container>
        <Row>
          <Col xl={5} lg={5} md={4} sm={6} xs={12}>
            <div className="wlcm-wrp">
              <img className="bee-logo" alt="logo" src={logo} />
              <div className="title">
                <p>
                  Welcome to BeeCard!
                </p>
              </div>
              <div className="sub-title">
                <p>Let's do a challenge!</p>
              </div>
              <div className="point-section">
                <ul>
                  <li>Download the BeeCard Business App</li>
                  <li>Log In</li>
                  <li>Scan the Customer Code</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xl={7} lg={7} md={8} sm={6} xs={12}>
            <div className="side-img">
              <img className="phone-img img-fluid" alt="logo" src={phoneImg} />
              <Button
                className="next-btn"
                onClick={() => history.push("/wlcm-two")}
              >
                SKIP <i className="fa-solid fa-angle-right"></i>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WelcomeOne;
