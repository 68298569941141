import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import beeLogo from "../../assets/images/simple_logo.svg";
import howItWork from "../../assets/images/how_it_work.svg";
import qr from "../../assets/images/qr_code.svg";
import seal from "../../assets/images/seal_card.svg";
import prize from "../../assets/images/prize.svg";
import { Link } from "react-router-dom";

const StepCard = ({ title, desc, img, top = "-80px" }) => {
  return (
    <Card className="bg-white card-rounded card-sahdow col-lg-4 mx-3">
      <CardBody>
        <div className="d-flex justify-content-between">
          <h2
            style={{
              fontSize: "88px",
              fontWeight: "bold",
              fontFamily: "'Nunito Sans'",
              color: "#454847",
            }}
          >
            {title}
          </h2>
          <img src={img} style={{ position: "absolute", top, right: 30 }} alt=""/>
        </div>

        <div className="mt-4">
          <p
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "'Nunito Sans'",
              color: "#454847",
            }}
          >
            {desc}
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

const HowItWork = () => {
  return (
    <Container fluid className="px-5">
      <Row>
        <Col lg="6" className="mt-lg-5">
          <h1
            style={{
              fontSize: "96px",
              fontWeight: "bold",
              fontFamily: "'Nunito Sans'",
              color: "#454847",
            }}
          >
            How does it work?
          </h1>
          <div>
            <img src={beeLogo} className="img-fluid" alt=""/>
          </div>
        </Col>
        <Col lg="6">
          <img src={howItWork} className="img-fluid" alt=""/>
        </Col>
      </Row>
      <Row className="mt-5 pt-5 card-group justify-content-between">
        <StepCard
          title="1"
          img={qr}
          desc="Your customers download the app and pass the code they have each time they buy from you"
        />
        <StepCard
          title="2"
          img={seal}
          desc="They are filling stamps of their card in the app"
        />
        <StepCard
          title="3"
          img={prize}
          top="-135px"
          desc="They change the prizes that you will give them in your trade"
        />
      </Row>
      <div className="mt-5 d-flex justify-content-end">
        <Button type="button" className="btn next-btn">
          <Link to="/profile-details" style={{ color: "#fff" }}>
            Continue <i className="fa-solid fa-angle-right text-white" />
          </Link>
        </Button>
      </div>
    </Container>
  );
};

export default HowItWork;
