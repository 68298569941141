import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ProfileSidePanel } from "../Helpers/ProfileSidePanel";
import { Row, Col, Container, Button, Input } from "reactstrap";
import { StoreRepository } from "../../repositories/store";

import QrImg from "../../assets/images/register_logo.svg";
import MobileImg from "../../assets/images/layouts/mobile-img.png";
import BeeMockUp from "../../assets/images/layouts/bee-mock-up.png";
import Remove from "../../assets/images/logo/remove.png";
import phoneimg from "../../assets/images/layouts/phone-img.png";

import "../../assets/scss/custom/pages/_profile-details.scss";
import { GENERIC_CATEGORY } from "../../core/constants/store";

const ProfileDetails = (props) => {
  const [logo, setLogo] = useState(null);
  const { history } = props;
  const [urlLogo, setUrlLogo] = useState(null);
  const [store, setStore] = useState(null);
  const catDescriptionRef = useRef(null);
  const [isGeneric, setIsGeneric] = useState(false);

  const uploadLogo = () => {
    document.getElementById("file").click();
  };

  const handleChange = (e) => {
    setLogo(e.target.files[0]);
    e.target.value = "";
  };

  const handleClick = async () => {
    const body = new FormData();
    body.append("image", logo);

    let nextStepUrl = "/prize-info";

    if (isGeneric) {
      nextStepUrl = "/setup-seals";
      const repository = new StoreRepository();
      await repository.partialUpdate({
        category_description: catDescriptionRef.current.value,
      });
    }

    if (logo) {
      new StoreRepository()
        .uploadLogo(body)
        .then((data) => {
          if (data) history.push(nextStepUrl);
          else console.log("error loading logo");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      history.push(nextStepUrl);
    }
  };

  useEffect(() => {
    new StoreRepository()
      .getStore()
      .then((data) => {
        setStore(data);
        if (data.configuration_completed) {
          history.push("/dashboard");
          return;
        }
        setUrlLogo(data.url_logo);
        setIsGeneric(data.store_category_id?.name === GENERIC_CATEGORY);
        setTimeout(
          () =>
            (catDescriptionRef.current.value = data.category_description || ""),
          350
        );
      })
      .catch((error) => console.log(error));
  }, []);

  const removeIcon = () => {
    setLogo(null);
  };

  return (
    <div className="profile-details m-0 p-0">
      <Container fluid className="m-0 p-0">
        <div className="main-wrp-page">
          <ProfileSidePanel
            isActive={1}
            image={BeeMockUp}
            imgClass={"profileImg"}
            isGeneric={isGeneric}
          />
          <div className="left-side-content">
            <Row>
              <Col className="m-0 p-0 content-heading">
                <h1 className="mx-lg-5">
                  Let's Get Started!
                </h1>
              </Col>
            </Row>
            <div className="personalize-profile-section">
              <p className="m-0 p-0 profile my-2">To begin, let's personalize your
              <br/>
              profile.
              </p>
              <div className="personalize-section">
                {isGeneric ? (
                  <Row>
                    <Col sm="12" lg="6" className="p-0 mb-4">
                      <p
                        style={{
                          color: "#575757",
                          fontSize: "24",
                          fontFamily: "'Avenir'",
                          fontWeight: "600",
                        }}
                      >
                        What type of business do you have?
                      </p>
                      <Input
                        name="category_description"
                        placeholder="Type of business"
                        innerRef={catDescriptionRef}
                      />
                      <div className="mt-1">
                        <span
                          style={{
                            color: "#575757",
                            fontFamily: '"Avenir"',
                            fontSize: "14px",
                          }}
                        >
                          Example: Veterinary
                        </span>
                      </div>
                    </Col>
                  </Row>
                ) : null}

                <Row className="align-items-center">
                  <Col className="m-0 p-0" md={0} lg={4} xl={0}>
                    <div className="attach">
                      <p className="m-0 p-0">Choose Your Profile Photo.</p>
                      <div
                        className="store-div"
                        style={{ display: logo ? "block" : "none" }}
                      >
                        <img
                          className="remove-icon"
                          style={{
                            display: logo ? "" : "none",
                          }}
                          onClick={removeIcon}
                          alt="remove icon"
                          src={Remove}
                        />

                        <img
                          className="store-logo"
                          alt="store logo"
                          style={{
                            display: logo ? "" : "none",
                          }}
                          src={logo ? URL.createObjectURL(logo) : null}
                        />
                        <span
                          className="change-logo"
                          onClick={uploadLogo}
                          style={{
                            display: logo ? "" : "none",
                          }}
                        >
                          Change Logo
                        </span>
                      </div>
                      <Input
                        type="file"
                        id="file"
                        style={{ display: "none" }}
                        onChange={handleChange}
                      />

                      <Button
                        style={{
                          display: logo ? "none" : "",
                        }}
                        onClick={uploadLogo}
                        className="upload-btn"
                      >
                        Upload Photo
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" md="5" lg="3" className="pt-4">
                    <hr />
                  </Col>
                </Row>
              </div>
              <div className="preview_section">
                <p className="m-0 p-0 prev-heading">Preview Example:</p>
                <Row className="align-items-center">
                  <Col className="m-0 p-0" md={0} lg={8} xl={0}>
                    <div className="bee-bussiness">
                      <div className="pt-4 px-0">
                        <img src={QrImg} alt="" className="img-fluid barcode" />
                        <p
                          className="beepara"
                          style={{ fontWeight: "200 !important" }}
                        >
                          This is how it will look
                        </p>
                        <p className="beepara_second text-bold">
                          in your client's app
                        </p>
                      </div>
                      <div className="mobile-div">
                        <img
                          src={MobileImg}
                          alt=""
                          className="img-fluid phone-img"
                        />

                        <div className="phone-under-div">
                          <div className="phone-img-img">
                            {urlLogo && !logo ? (
                              <img
                                className="img-fluid"
                                alt="store-logo"
                                src={urlLogo}
                              ></img>
                            ) : logo ? (
                              <img
                                src={logo ? URL.createObjectURL(logo) : null}
                                alt=""
                                className="img-fluid phone-img-code"
                              />
                            ) : (
                              <img
                                alt="logo"
                                src={store?.store_category_id?.category_logo}
                              ></img>
                            )}
                          </div>
                          <div className="phone-details">
                            <p className="phone-details-heading">
                              {store ? (
                                <span>{store.name}</span>
                              ) : (
                                <span>
                                  {" "}
                                  Store <span>Puntos Bee</span>
                                </span>
                              )}
                            </p>
                            <span className="phone-details-miam">
                              <i className=""></i>0 members
                            </span>
                            <p className="phone-details-low">
                              Not yet a member
                            </p>
                            <button className="phone-button">
                              BECOME A MEMBER
                            </button>
                          </div>
                        </div>
                        <img
                          src={phoneimg}
                          alt=""
                          className="img-fluid phone-img-2"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="line_button_section">
                  <Row className="align-items-center">
                    <Col className="m-0 p-0" md={0} lg={5} xl={0}>
                      <div className="line_section"></div>
                    </Col>
                    <Col className="m-0 p-0" md={0} lg={4} xl={0}>
                      <div className="line_button">
                        <Button onClick={handleClick} className="next-btn">
                          {!isGeneric ? "LAST STEP " : "CONTINUE"}
                          <i className="fa-solid fa-angle-right text-white"></i>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

ProfileDetails.propTypes = {
  history: PropTypes.object,
};

export default ProfileDetails;
