import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ProfileSidePanel } from "../Helpers/ProfileSidePanel";
import { Row, Col, Container, Button, CardBody, Card } from "reactstrap";
import Xarrow from "react-xarrows";
import { StoreRepository } from "../../repositories/store";
import BeeMockUp from "../../assets/images/generic_seal.png";
import bronze from "../../assets/images/prize-bronze.svg";
import silver from "../../assets/images/prize-silver.svg";
import gold from "../../assets/images/prize-gold.svg";
import "./SealSetUp.css";
import { GENERIC_CATEGORY } from "../../core/constants/store";
import { useHistory } from "react-router-dom";

import "../../assets/scss/custom/pages/_profile-details.scss";

const SealCard = ({ children }) => {
  return (
    <Card
      className="card-rounded"
      style={{ border: "2px solid #ccc", height: "4.5rem", width: "4.5rem" }}
    >
      <CardBody>{children}</CardBody>
    </Card>
  );
};

const SealSetUp = () => {
  const [amount, setAmount] = useState("");
  const history = useHistory();

  useEffect(() => {
    new StoreRepository()
      .getStore()
      .then((data) => {
        if (data.store_category_id.name !== GENERIC_CATEGORY) {
          history.push("/prize-info");
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const handleValueChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setAmount(e.target.value);
    }
  };

  const getAmount = (times) => {
    if (!!amount) {
      const intl = Intl.NumberFormat();
      return intl.format(parseInt(amount) * times);
    }
    return `x${times}`;
  };

  const getTotalAmount = () => {
    return parseInt(amount) * 10;
  };

  const handleClick = async () => {
    if (!!amount) {
      const repository = new StoreRepository();
      const response = await repository.setCardAmount(getTotalAmount());
      if (response.status !== 200 && response.status !== 201) {
        
      } else {
        history.push("/setup-generic-prize/1");
      }
    }
  };

  return (
    <div className="profile-details m-0 p-0">
      <Container fluid className="m-0 p-0">
        <div className="main-wrp-page">
          <ProfileSidePanel
            isActive={2}
            image={BeeMockUp}
            imgClass={"seal-img"}
            isGeneric
            imgWrapperClass=""
          />
          <div className="left-side-content py-3">
            <Row>
              <Col sm="12" lg="10" className="m-0 p-0 content-heading">
                <h1 className="text-center">
                  Define how much your customer should consume in your business to
                  fill a stamp
                </h1>
              </Col>
              <Col sm="12">
                <div className="p-0 m-0">
                  <p
                    className="d-flex justify-content-center m-0 p-0"
                    style={{
                      color: "#575757",
                      fontSize: "18px",
                      fontFamily: "'Avenir'",
                    }}
                  >
                    <h6
                      style={{
                        color: "#575757",
                        fontWeight: "bolder",
                        fontSize: "22px",
                      }}
                    >
                      Suggestion: &nbsp;
                    </h6>{" "}
                      The amount of your stamps should be that of an average purchase
                      in your business.
                  </p>
                  <p className="text-center p-0 m-0">
                    <i style={{ color: "#575757", fontFamily: "'Avenir'" }}>
                      Example: If you are a dentist and normally when you
                      visit a customer spend ₡25,000, your stamps should
                      be filled with that amount{" "}
                    </i>
                  </p>
                </div>
              </Col>
            </Row>
            <div className="personalize-profile-section ">
              <div className="text-center">
                <div className="text-center">
                  <div className="input-group  d-flex justify-content-center align-items-end">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text seal-suffix"
                        style={{ fontSize: "48px", paddingBottom: "1rem" }}
                      >
                        <span>₡</span>
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="0"
                      value={amount}
                      onChange={handleValueChange}
                      className="form-control seal-input"
                    />
                  </div>
                </div>
                <div className="mt-1">
                  <span>Enter the amount for each stamp</span>
                </div>
                <div className="mt-4">
                  <Row>
                    <Col sm="12" lg="3" className="level-bronce">
                      <div>
                        <span
                          id="level-bronce"
                          style={{
                            fontSize: "32px",
                            fontWeight: "bold",
                            fontFamily: "'Avenir'",
                          }}
                        >
                          First Prize
                        </span>
                        <h5 style={{ color: "#EBA97D", fontSize: "20px" }}>
                          Bronze
                        </h5>
                        <div className="px-3">
                          <Card
                            className="card-rounded"
                            style={{ backgroundColor: "#EBA97D" }}
                          >
                            <CardBody>
                              <div className="text-white ">
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "'Avenir'",
                                  }}
                                  className="text-white"
                                >
                                  ₡
                                </span>{" "}
                                <span
                                  className="text-white"
                                  style={{
                                    fontSize: "24px",
                                    fontFamily: "'Avenir'",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {getAmount(1)}
                                </span>
                              </div>
                              <p
                                className="text-white"
                                style={{ fontSize: "16px" }}
                              >
                                Must consume to get this award
                              </p>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </Col>
                    <Col
                      sm="12"
                      lg={{ size: 6 }}
                      className="card-prize__container"
                    >
                      <Card className="card-rounded card-shadow">
                        <CardBody className="card-prize__body">
                          <div>
                            <h2
                              style={{
                                color: "#B2B2B2",
                                fontFamily: "'Avenir'",
                              }}
                            >
                              CARD OF YOUR CLIENT
                            </h2>
                          </div>
                          <Row className="card-prize__items">
                            <Col sm={{ offset: 1, size: 2 }}>
                              <SealCard>
                                <div
                                  className="text-center"
                                  style={{
                                    width: "4.5rem",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                  }}
                                >
                                  <img
                                    id="bronze"
                                    src={bronze}
                                    style={{
                                      width: "100%",
                                    }}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </SealCard>
                            </Col>
                            <Col sm="2">
                              <SealCard>
                                <b
                                  style={{
                                    color: "#B2B2B2",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  2
                                </b>
                              </SealCard>
                            </Col>
                            <Col sm="2">
                              <SealCard>
                                <b
                                  style={{
                                    color: "#B2B2B2",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  3
                                </b>
                              </SealCard>
                            </Col>
                            <Col sm="2">
                              <SealCard>
                                <div
                                  className="text-center"
                                  style={{
                                    width: "4.5rem",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                  }}
                                >
                                  <img
                                    id="silver"
                                    src={silver}
                                    style={{
                                      width: "100%",
                                    }}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </SealCard>
                            </Col>
                            <Col sm="2">
                              <SealCard>
                                <b
                                  style={{
                                    color: "#B2B2B2",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  5
                                </b>
                              </SealCard>
                            </Col>
                            <Col sm={{ offset: 1, size: 2 }}>
                              <SealCard>
                                <b
                                  style={{
                                    color: "#B2B2B2",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  6
                                </b>
                              </SealCard>
                            </Col>
                            <Col sm="2">
                              <SealCard>
                                <b
                                  style={{
                                    color: "#B2B2B2",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  7
                                </b>
                              </SealCard>
                            </Col>
                            <Col sm="2">
                              <SealCard>
                                <b
                                  style={{
                                    color: "#B2B2B2",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  8
                                </b>
                              </SealCard>
                            </Col>
                            <Col sm="2">
                              <SealCard>
                                <b
                                  style={{
                                    color: "#B2B2B2",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  9
                                </b>
                              </SealCard>
                            </Col>
                            <Col sm="2">
                              <SealCard>
                                <div
                                  className="text-center"
                                  style={{
                                    width: "4.5rem",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                  }}
                                >
                                  <img
                                    id="gold"
                                    src={gold}
                                    style={{
                                      width: "100%",
                                    }}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </SealCard>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Xarrow
                        start="level-bronce"
                        end="bronze"
                        lineColor="#EBA97D"
                        color="#EBA97D"
                        dashness
                        tailShape="circle"
                        showTail
                        tailSize={3}
                        showHead={false}
                      />
                      <Xarrow
                        start="level-silver"
                        end="silver"
                        lineColor="#B2B2B2"
                        color="#B2B2B2"
                        dashness
                        tailShape="circle"
                        showTail
                        tailSize={3}
                        showHead={false}
                        className="level-silver"
                      />
                      <Xarrow
                        start="level-gold"
                        end="gold"
                        dashness
                        color="#DEA01A"
                        lineColor="#DEA01A"
                        tailShape="circle"
                        showTail
                        tailSize={3}
                        showHead={false}
                      />
                    </Col>
                    <Col sm="12" lg={{ size: 3 }} className="silver-card__item">
                      <Row>
                        <Col sm="12" lg={{ size: 11 }}>
                          <span
                            id="level-silver"
                            style={{
                              fontSize: "32px",
                              fontWeight: "bold",
                              fontFamily: "'Avenir'",
                            }}
                          >
                            Second Prize
                          </span>
                          <h5 style={{ color: "#B2B2B2", fontSize: "20px" }}>
                            Platinum
                          </h5>
                          <div>
                            <Card
                              className="card-rounded"
                              style={{ backgroundColor: "#B2B2B2" }}
                            >
                              <CardBody>
                                <div className="text-white">
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "'Avenir'",
                                    }}
                                    className="text-white"
                                  >
                                    ₡
                                  </span>{" "}
                                  <span
                                    className="text-white"
                                    style={{
                                      fontSize: "24px",
                                      fontFamily: "'Avenir'",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {getAmount(4)}
                                  </span>
                                </div>
                                <p
                                  className="text-white"
                                  style={{ fontSize: "16px" }}
                                >
                                  Must consume to get this award
                                </p>
                              </CardBody>
                            </Card>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" lg={{ offset: 1, size: 11 }}>
                          <div className="ml-5">
                            <span
                              id="level-gold"
                              style={{
                                fontSize: "32px",
                                fontWeight: "bold",
                                fontFamily: "'Avenir'",
                              }}
                            >
                              Third Prize
                            </span>
                            <h5 style={{ color: "#DEA01A", fontSize: "20px" }}>
                              Gold
                            </h5>
                            <Card
                              className="card-rounded"
                              style={{ backgroundColor: "#DEA01A" }}
                            >
                              <CardBody>
                                <div className="text-white">
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontFamily: "'Avenir'",
                                    }}
                                    className="text-white"
                                  >
                                    ₡
                                  </span>{" "}
                                  <span
                                    className="text-white"
                                    style={{
                                      fontSize: "24px",
                                      fontFamily: "'Avenir'",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {getAmount(10)}
                                  </span>
                                </div>
                                <p
                                  className="text-white"
                                  style={{ fontSize: "16px" }}
                                >
                                  Must consume to get this award
                                </p>
                              </CardBody>
                            </Card>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="personalize-section">
                <div className="line_button_section">
                  <Row className="align-items-center">
                    <Col className="m-0 p-0" sm="0" md={0} lg={5} xl={0}>
                      <div className="line_section"></div>
                    </Col>
                    <Col className="m-0 p-0" md={0} lg={5} xl={0}>
                      <div className="line_button mx-2">
                        <Button
                          onClick={handleClick}
                          className="next-btn"
                          disabled={!!!amount}
                          style={{
                            backgroundColor:
                              "linear-gradient(180deg, #b924b4, #8525b7) !important",
                          }}
                        >
                          CONTINUE
                          <i className="fa-solid fa-angle-right text-white"></i>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

SealSetUp.propTypes = {
  history: PropTypes.object,
};

export default SealSetUp;
